h4 {
    margin-bottom: 10px;
    margin-top: 0;
}

.outer-div  {
    display: flex;
    flex-direction: column;
}

.split {
    width: 50%;
    z-index: 1;
    overflow-x: hidden;
}

/* Control the left side */
.left {
    left: 0;
    border-right: 1px solid black;
}

/* Control the right side */
.right {
    right: 0;
}

.top-ingredient-picker {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 10px;
    margin-bottom: 10px;
}

.bottom-ingredient-picker {
    display: flex;
    flex-direction: row;
}

.outer-ingredient-picker {
    display: flex;
    flex-direction: column;
}

.title {
    display: flex;
    justify-content: center;
    align-items: center;
}

.subtitle {
    display: flex;
    justify-content: center;
    align-items: center;
    font-style: italic;
    margin-top: 75px;
}

.flex-row {
    display: flex;
    flex-direction: row;
}

.flex-row-center {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.flex-row-space-evenly {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}

.flex-row-flex-start {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.flex-column {
    display: flex;
    flex-direction: column;
}

.margin-above {
    margin-top: 10px;
}

.margin-below {
    margin-bottom: 10px;
}

.margin-left {
    margin-left: 10px;
}

.margin-right {
    margin-right: 10px;
}

.search-box {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
}

.advanced-filters {
    margin-top: 10px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
}

.advanced-filter-menu-content {
    max-height: 300px;
    overflow-y: auto;
    padding: 3px;
}

.filter-buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    margin: 5px;
}

.ingredients-amount {
    margin-top: 10px;
    margin-left: 20px;
    margin-right: 20px;
}