
.cocktail-menu {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin-top: 10px;
    border: 50px solid;
    border-image-source: url(../tikiborder.png);
    border-image-slice: 18%;
    width: calc(100% - 100px);
    font-family: 'Indie Flower', 'Comic Sans', serif;
}
.cocktail-menu-items {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
}

.cocktail-items-outer-box {
    border-top: 1px solid #d4af37;
    width: 100%;
}

.items-list {
    border-right: 1px solid #d4af37;
    width: 350px;
}

.cocktail-menu-item-border {
    margin-bottom: 10px;
}

.cocktail-menu-item-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.cocktail-menu-item-title {
    border-bottom: 1px dotted #d4af37;
    margin-bottom: 5px;
    margin-top: 5px;
    padding-bottom: 5px;
    font-size: 24px;
    text-align: center;
}

.cocktail-menu-item-ingredients {
    padding: 10px;
    text-align: center;
}

.cocktail-menu-width {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.title-and-qr {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

.title-and-description-outer {
    width: calc(100% - 75px);
}
.title-and-description-inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 75px;
}

.cocktail-menu-title {
    margin-bottom: 15px;
    font-size: 40px;
    text-align: center;
}

.cocktail-menu-description {
    font-size: 20px;
    text-align: center;
    width: 100%;
    border-bottom: 1px dotted #d4af37;
    padding-bottom: 30px;
}

.full-width {
    width: 100%;
}