.recipe-outer {
    background-color: #f7f7f7;
    width: 100%;
    display: flex;
    justify-content: center;
    min-height: calc(100vh - 66px);
}

.recipe {
    padding: 16px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    border-left: 1px solid #d4af37;
    border-right: 1px solid #d4af37;
    border-bottom: 1px solid #d4af37;
    width: 928px;
    background-color: white;
    height: 100%;
}

.title-and-book {
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.flex-row-space-between {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.flex-row-align-center {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.tags {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
}

.ingredients-full {
    min-width: 33%;
    max-width: 33%;
}

.instructions-full {
    min-width: calc(66% - 62px);
    max-width: calc(66% - 62px);
}

.vertical-divider {
    border: 1px solid #d4af37;
    margin-left: 30px;
    margin-right: 30px;
}

.ingredient-with-divider {
    border-top: 1px solid #d4af37;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.ingredient-without-divider {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.ingredient-multiplier {
    border: 1px solid #d4af37;
    border-radius: 9999px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 75%;
}

.center {
    display: flex;
    justify-content: center;
}

.ingredients-and-instructions {
    display: flex;
    flex-direction: row;
    width: 100%;
    border-bottom: 2px solid #d4af37;
}

.ingredients-and-instructions-empty {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.first-instruction {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px;
}

.instruction {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px;
    border-top: 1px solid #d4af37;
}

.number {
    margin-right: 10px;
    border: 1px solid #d4af37;
    border-radius: 9999px;
    width: 30px;
    min-width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}


.facts {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    flex-wrap: wrap;
    width: 100%;
    padding-top: 20px;
    padding-bottom: 20px;
}

.ingredients-outer {
    background-color: #f7f7f7;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: calc(100vh - 65px);
}

.ingredients-you-have-a-choice-to-make {
    padding: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid #d4af37;
    width: 500px;
    background-color: white;
    margin-top: 10px;
    margin-bottom: 10px;
}

.ingredients-you-have-a-choice-to-make-small {
    padding: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid #d4af37;
    width: 250px;
    background-color: white;
    margin-top: 10px;
    margin-bottom: 10px;
}

.mobile-card {
    margin-top: 10px;
    margin-bottom: 10px;
}

.total-recipes {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #d4af37;
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 8px;
    margin-left: 8px;
    margin-right: 8px;
}