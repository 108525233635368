.outer {
    height: calc(100vh - 64px);
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: linear-gradient(
            to bottom,
            lightgrey,
            white,
            lightgrey
    );
    width: 100%;
}

.inner {
    padding: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.element {
    margin-bottom: 10px;
}