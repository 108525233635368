body {
    background-color: #FFF;
}

.flex-row {
    display: flex;
    flex-direction: row;
}

.flex-row-center {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.flex-row-top {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100vw;
}

.flex-column {
    display: flex;
    flex-direction: column;
}

.flex-column-center {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.header {
    width: 100%;
    border-bottom: 1px solid #d4af37;
}

.recipes {
    margin-top: 10px;
}

.recipe-cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    overflow: hidden;
}

.recipe-card {
    height: inherit;
    margin: 5px;
}

.recipe-card-cantmake {
    height: inherit;
    opacity: 0.6;
    margin: 5px;
}

.filter-menu {
    display: flex;
    flex-direction: column;
    margin-right: 10px;
    margin-left: 10px;
    margin-top: 10px;
}

.search-and-filter-menu {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.filter-button-div-large {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-left: 20px;
}

.filter-button-div-small {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
}

.filter-divider {
    border-bottom: 2px dotted #d4af37;
    width: 80%;
    margin-top: 5px;
    margin-bottom: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.advanced-filter-block {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 10px;
}

.filter-title {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.filter-chips {
    display: flex;
    flex-wrap: wrap;
    width: 75%;
    justify-content: center;
    align-items: center;
}

.book-title {
    text-align: center;
    margin-top: 10px;
}